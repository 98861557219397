import { NgxOrgChartModule } from 'ngx-org-chart';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbAccordionModule, NbActionsModule,
  NbAlertModule, NbButtonModule,
  NbCardModule, NbCheckboxModule, NbContextMenuModule, NbIconModule,
  NbInputModule, NbListModule,
  NbMenuModule,
  NbProgressBarModule, NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule, NbTabsetModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
// formly
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxUploadModule } from '@wkoza/ngx-upload';
import { DxAccordionModule, DxButtonModule, DxDataGridComponent, DxDataGridModule, DxDateBoxModule, DxDropDownButtonModule, DxFilterBuilderModule, DxListModule, DxLoadPanelModule, DxPivotGridComponent, DxPivotGridModule, DxPopupComponent, DxPopupModule, DxSchedulerModule, DxSelectBoxModule, DxSwitchModule, DxTemplateModule, DxTreeListModule, DxScrollViewModule, DxTabsModule, DxFileUploaderModule, DxTreeMapModule, DxGalleryModule, DxBarGaugeModule, DxRadioGroupModule, DxCheckBoxModule, DxTreeViewModule, DxRangeSliderModule, DxTileViewModule, DxRangeSelectorModule } from 'devextreme-angular';
import { DxoScrollingComponent } from 'devextreme-angular/ui/nested';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgtreegridComponent, NgtreegridModule } from 'ngtreegrid';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxTinymceModule } from 'ngx-tinymce';
import { EnvService } from '../@core/backend/common/services/env.service';
import { ThemeModule } from '../@theme/theme.module';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { FormChecklistComponentComponent } from './form-checklist-component/form-checklist-component.component';
import { FormClassComponent } from './form-class/form-class.component';
import { FormlyFieldDatepicker } from './formlyFieldDatePicker';
import { FormlyFieldDateTimepicker } from './formlyFieldDateTimePicker';
import { FormlyFieldTypeahead } from './formlyFieldTypeahead';
import { FormlyFieldMap } from './formlyFieldMap';
import { ModalListClassComponent } from './modal-list-class/modal-list-class.component';
import { ModalAssetDetailsComponent } from './modal-asset-details/modal-asset-details.component';
import { ModalChecklistComponentComponent } from './modal-checklist-component/modal-checklist-component.component';
import { ModalClassEditComponent } from './modal-class-edit/modal-class-edit.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalCruscottoListAssetComponent } from './modal-cruscotto-list-asset/modal-cruscotto-list-asset.component';
import { ModalFilterComponent } from './modal-filter/modal-filter.component';
import { ModalGenericComponent } from './modal-generic/modal-generic.component';
import { ModalRelationSelectComponent } from './modal-relation-select/modal-relation-select.component';
import { ModalUploadComponent } from './modal-upload/modal-upload.component';
import { PanelWrapperComponent } from './panel-wrapper.component';
import { RelationsGraphComponent } from './relations-graph/relations-graph.component';
import { RelationsListComponent } from './relations-list/relations-list.component';
import { SchedularClassComponent } from './schedular-class/schedular-class.component';
import { SmartTableCheckboxComponent } from './smart-table-checkbox';
import { TableActionOpenChecklistComponentComponent } from './table-action/table-action-open-checklist-component/table-action-open-checklist-component.component';
import { NgxValidationMessageComponent } from './validation-message/validation-message.component';

import { WidgetNotificheComponent } from './widget-notifiche/widget-notifiche.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormlyFieldTabs } from './formlyFieldTabs';
import { ModalListMultiClassComponent } from './modal-list-multi-class/modal-list-multi-class.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import * as moment from 'moment';
import { WidgetUbicazioniComponent } from './widget-ubicazioni/widget-ubicazioni.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { YearPickerComponent } from './year-picker/year-picker.component';
import { MapClassComponent } from './map-class/map-class.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { NgxSidebarControlModule } from '@runette/ngx-leaflet-sidebar';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalIdoneitaComponent } from './modal-idoneita/modal-idoneita.component';
import { SelectListClassComponent } from './list-class/list-class.component';
import { MappaComponent } from './mappa/mappa.component';
import { ModalMappaComponent } from './modal-mappa/modal-mappa.component';
import { ModalGenericDatagridComponent } from './modal-generic-datagrid/modal-generic-datagrid.component';
import { MapComponent } from './map/map.component';
import { FormlyFieldPreviewAllegati } from './formlyFieldPreviewAllegati';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { ModalSelectMansioneComponent } from './modal-select-mansione/modal-select-mansione.component';
import { RangePickerComponent } from './date-picker/range-picker.component';
import { ModalListClassSimpleComponent } from './modal-list-class-simple/modal-list-class-simple.component';
import { FormlyFieldDynamicAhead } from './formlyFieldTypeaheadDynamic';



const COMPONENTS = [
  NgxValidationMessageComponent,
  ModalAssetDetailsComponent,
  RangePickerComponent,
  ModalGenericComponent,
  ModalFilterComponent,
  ModalConfirmComponent,
  ModalClassEditComponent,
  PanelWrapperComponent,
  FormlyFieldDatepicker,
  FormlyFieldDateTimepicker,
  FormlyFieldTypeahead,
  FormlyFieldTabs,
  FormlyFieldMap,
  FormlyFieldPreviewAllegati,
  FormlyFieldDynamicAhead,
  ModalRelationSelectComponent,
  ModalUploadComponent,
  SmartTableCheckboxComponent,
  FormClassComponent,
  MapComponent,
  ModalListClassComponent,
  WidgetNotificheComponent,
  RelationsListComponent, RelationsGraphComponent,
  FilePickerComponent,
  SchedularClassComponent,
  NotFoundComponent,
  WidgetUbicazioniComponent,
  MonthPickerComponent,
  YearPickerComponent,
  ModalIdoneitaComponent,
  MappaComponent,
  ModalGenericDatagridComponent,
  ModalImageComponent,
  ModalSelectMansioneComponent,
  ModalListClassSimpleComponent
];
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return '';
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function maxValidationMessage(err, field) {
  return `Il valore inserito deve avere al massimo ${field.templateOptions.myMaxLength} caratteri`;
}
export class myMaxLenghtValidators {
  static maxLength() {
    return (control: FormControl, field: FormlyFieldConfig): any => {
      if (!control.value) {
        return true;
      }
      const maxLength = field.templateOptions.myMaxLength;

      if (control.value.length > maxLength) {
        // force the length to
        return false;
      }

      return true;
    };
  }
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbButtonModule,
    NbSpinnerModule,
    NbStepperModule,
    NbAlertModule,
    NgSelectModule,
    NbInputModule,
    ThemeModule,
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbAccordionModule, NbTooltipModule,
    NbCheckboxModule,
    Ng2SmartTableModule,
    NbProgressBarModule,
    NbTreeGridModule,
    DxTreeListModule,
    DxDataGridModule,
    DxPivotGridModule,
    DxAccordionModule,
    DxListModule,
    DxTemplateModule,
    DxPopupModule,
    DxFilterBuilderModule,
    DxLoadPanelModule,
    DxScrollViewModule,
    DxTabsModule,
    DxFileUploaderModule,
    DxGalleryModule,
    DxBarGaugeModule,
    DxRangeSliderModule,
    DxRangeSelectorModule,
    NgxUploadModule.forRoot(),
    FormlyModule.forRoot(
      {

        types: [
          { name: 'date', component: FormlyFieldDatepicker },
          { name: 'datetime', component: FormlyFieldDateTimepicker },
          { name: 'typeahead', component: FormlyFieldTypeahead },  
          { name: 'dynamicahead', component: FormlyFieldDynamicAhead },
          { name: 'tabs', component: FormlyFieldTabs },
          { name: 'mapfield', component: FormlyFieldMap },
          { name: 'previewattachment', component: FormlyFieldPreviewAllegati},
          {
            name: 'minmax',
            extends: 'formly-group',
            wrappers: ['form-field'],
            defaultOptions: {

              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  type: 'input', key: 'min',
                  templateOptions: { type: 'number', placeholder: 'min.' },
                  className: 'col-6 pr-0 mr-0',

                },
                {
                  type: 'input', key: 'max',
                  templateOptions: { type: 'number', placeholder: 'max.' },
                  className: 'col-6 pl-0 mr-0',
                },
              ],
            },
          },
        ],
        wrappers: [
          { name: 'panelgroup', component: PanelWrapperComponent },
        ],
        validationMessages: [
          { name: 'required', message: 'Il campo è obbligatorio' },
          { name: 'myMaxLenght', message: maxValidationMessage },
        ],
        validators: [
          {name: 'myMaxLenght', validation: myMaxLenghtValidators.maxLength()},
        ],
      },
    ),
    FormlyBootstrapModule,
    TabsModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'none',
      useDefaultLang: true,
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxTinymceModule.forRoot({
      baseURL: '/assets/tinymce/',
    }),
    DxDropDownButtonModule,
    DxButtonModule,
    DxDateBoxModule,
    NgxSpinnerModule,
    NgxEchartsModule,
    NgtreegridModule,
    NgbModule,
    NgxFileHelpersModule,
    DxSchedulerModule,
    DxSelectBoxModule,
    DxRadioGroupModule,
    DxListModule,
    DxSwitchModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxDateBoxModule,
    DxTreeMapModule,
    DxCheckBoxModule,
    DxTreeViewModule,
    RouterModule,
    NgCircleProgressModule.forRoot(),
    NgxOrgChartModule,
    LeafletModule,
    LeafletDrawModule,
    NgxSidebarControlModule,
    NgxLoadingModule.forRoot({}),
    DxTileViewModule
  ],
  entryComponents: [ModalIdoneitaComponent,
    SmartTableCheckboxComponent, ModalCruscottoListAssetComponent,
    ModalClassEditComponent, ModalUploadComponent, ModalChecklistComponentComponent, ModalGenericDatagridComponent,
    TableActionOpenChecklistComponentComponent, SelectListClassComponent,ModalListClassComponent, ModalListMultiClassComponent,ModalMappaComponent,ModalListClassSimpleComponent],
  exports: [...COMPONENTS,
    RouterModule, DxDateBoxModule,MappaComponent,ModalMappaComponent,
    TableActionOpenChecklistComponentComponent, DxPopupComponent, DxButtonModule,
    DxPivotGridComponent, DxoScrollingComponent, DxDataGridComponent,DxCheckBoxModule, DxTreeViewModule,NgtreegridComponent,
    ReactiveFormsModule, FormlyModule, TranslateModule, NgbModule, NgxFileHelpersModule, ModalGenericDatagridComponent,
     DxSchedulerModule, DxSelectBoxModule, DxListModule, DxSwitchModule, DxTreeListModule, NgCircleProgressModule,DxRangeSliderModule,
     TabsModule, DxScrollViewModule, NgxOrgChartModule,MapClassComponent,FormClassComponent,SelectListClassComponent,ModalListClassComponent,ModalImageComponent,ModalListClassSimpleComponent
    ],
  declarations: [...COMPONENTS,
    FormChecklistComponentComponent,
    ModalCruscottoListAssetComponent,
    ModalChecklistComponentComponent,
    TableActionOpenChecklistComponentComponent,ModalListClassSimpleComponent,
    SelectListClassComponent,ModalListClassComponent, ModalListMultiClassComponent, MapClassComponent, ModalMappaComponent, MapComponent
  ],
})
export class ComponentsModule {
  constructor(
    private envService: EnvService,
    public translate: TranslateService,
  ) {
    translate.use(envService.defaultLanguage);
  }
}
