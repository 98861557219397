import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs/Subject';

import { LookupService } from '../@core/backend/common/services/lookup.service';
import { GenericClassService } from '../@core/backend/common/services/genericClass.service';
import { ModalListClassComponent } from './modal-list-class/modal-list-class.component';
import { NbDialogService } from '@nebular/theme';
import { isArray } from 'util';
import { ModalListClassSimpleComponent } from './modal-list-class-simple/modal-list-class-simple.component';

@Component({
  selector: 'formly-field-typeahead-dynamic',
  template: `
  <div  class="form-group" [class.has-error]="showError">
    <div *ngIf="to.label && to.hideLabel !== true" class="ui-widget">
        <label [for]="id">
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
        </label>
    </div>
    <div  *ngIf="!mode" class="m-0 p-0">
      <input style="opacity:0.4" type="text" readonly class="form-control" placeholder="caricamento..." />
    </div>
    <div *ngIf="mode=='dialog'" class="m-0 p-0">
      <div class="input-group" *ngIf="!to.disabled">
        <input [ngStyle]="assetInCantiere && {'background':'#e9ecef'}" style="background:#fff;border-right:none" type="text"
          readonly class="form-control" [placeholder]="!to.readonly?to.placeholder:''"
          [value]="formControl.value?getDescription():''" [disabled]="assetInCantiere"
        >
        <div class="input-group-append">
          <button *ngIf="formControl.value" class="input-group-text bg-transparent" type="button" (click)="clearDialog($event)" [disabled]="assetInCantiere">x</button>
          <button class="btn btn-warning m-0" type="button" (click)="openDialog($event,'asset')" [disabled]="assetInCantiere"><i class="fas fa-bars"></i>&nbsp;Asset</button>
          <button class="btn btn-warning m-0" type="button" (click)="openDialog($event,'place')" [disabled]="assetInCantiere"><i class="fas fa-bars"></i>&nbsp;Ubicazione</button>
        </div>
      </div>

      <div  *ngIf="to.disabled">
        <input disabled class="form-control" [placeholder]="to.placeholder" [value]="getDescription() ? getDescription(): ''">
      </div>
      <input type="hidden"  [required]="to.required" [formControl]="formControl">
    </div>
    <ng-select *ngIf="mode=='select'" [items]="to.options"
        [placeholder]="!to.readonly?to.placeholder:''"
        [required]="to.required"
        [typeahead]="search$"
        [formControl]="formControl"
        [readonly]="to.readonly"
        bindValue="Id"
        bindLabel="Description"
        (change)="changeSelect($event)">
        <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.IconFont" class="{{item.IconFont}}" [style.color]="item.IconColor">&nbsp;&nbsp;</span>
            <span [style.color]="item.TextColor">{{item.Description}}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <span *ngIf="item.IconFont" class="{{item.IconFont}}" [style.color]="item.IconColor">&nbsp;&nbsp;</span>
            <span [style.color]="item.TextColor" >{{item.Description}}</span>
        </ng-template>

    </ng-select>

    <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
  </div>
  `,
})
export class FormlyFieldDynamicAhead extends FieldType implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();

  items;
  selectDescription;
  mode: any;
  oldValue: any = null;
  assetInCantiere: boolean;

  constructor(
    private lookupService: LookupService,
    private classService: GenericClassService,
    private dialogService: NbDialogService,
  ) {

    super();

  }

  ngOnInit() {
    this.mode = null;

    if (!this.formState.selectOptionsData) {
      this.formState.selectOptionsData = [];
    }
    if (this.to.reference) {
      // Caricamento reference
      console.log(this.to)
      this.formState.selectOptionsData[this.field.key] = null;

      const referenceCascade = this.field['referenceCascade'];


      const filtroEval = this.calculateExpression(this.to['filterReference'], this.model);
      this.classService.getDataReference(this.to.reference, this.formControl.value || 0, filtroEval).then((res) => {

        this.formState.selectOptionsData[this.field.key] = res ? res.list.data : [];
        if(this.to.fieldType && this.to.fieldType == "dynamic") { //filtro per record 
          this.formState.selectOptionsData[this.field.key] = this.filterPadri(this.formState.selectOptionsData[this.field.key]);
        }
        console.log('CARICATE OPTION ' + this.field.key);
        let selectItem = this.formState.selectOptionsData[this.field.key].find(x => x.Id == this.formControl.value);
        // if (res.total > 15) {
        if ((this.formState.selectOptionsData[this.field.key].lenght > 15 || referenceCascade || this.to['filterReference']) && !this.to.readonly) {

          // this.selectDescription = selectItem ? selectItem['Description'] : '';
          this.mode = 'dialog';

        } else {
          this.to.options = this.formState.selectOptionsData[this.field.key];
          this.mode = 'select';
        }
        
        if (this.to.setFirstAsDefault && !selectItem) {
          if (this.formState.selectOptionsData[this.field.key] && this.formState.selectOptionsData[this.field.key].length > 0) {
            selectItem = this.formState.selectOptionsData[this.field.key][0];
            this.selectDescription = selectItem ? selectItem['Description'] : '';
          }
        }
        this.changeSelect(selectItem);
      });
    }

  }

  filterPadri(items) {
    return items.filter(item => this.hasFigli(item,items));
  }

  // Metodo per verificare se un elemento ha figli
  hasFigli(item: any,items:any): boolean {
    const id = item.Id;
    return items.some(subItem => subItem.ParentId === id);
  }

  getDescription() {
    let selectItem = null;
    if (this.oldValue != this.formControl.value) {

       selectItem = this.formState.selectOptionsData[this.field.key].find(x => x.Id == this.formControl.value);

      if (this.to.setFirstAsDefault && !selectItem) {
        if (this.formState.selectOptionsData[this.field.key] && this.formState.selectOptionsData[this.field.key].length > 0) {
          selectItem = this.formState.selectOptionsData[this.field.key][0];
        }
      }
      this.selectDescription = selectItem ? selectItem['Description'] : '';

    }

    if (selectItem && this.oldValue != this.formControl.value) {

      console.log('CHANGE DETECTED ON ' + this.field.key);
      this.oldValue = this.formControl.value;
      this.changeSelect(selectItem);
    }

    return this.selectDescription;
  }
  calculateExpression(f, o) {
    'use strict';
    if (!f) return null;


    const copy = [ ...f];
    const m = o;
    const model = o;
    copy.forEach((element, index) => {
      try {
        if (isArray(element)) {
          copy[index] = this.calculateExpression(element, o);
        } else {
          copy[index] = eval(element);
        }

      } catch (error) {
        if (element.includes('m.')||element.includes('model.')) copy[index]=null
      }

    });
    return copy;
  }
  openDialog(e,type) {
    let classRerefence = '';
    let domainName = '';
    let otherClass = '';
    let filterReference = null;
    let selectFilterReference = null;
    let treeRoot = null;

    const newFilter = this.to['filterReference'] ? [this.to['filterReference']] : ['Status', '=', 'A'];
    if (this.field['referenceCascade']) {
      if (newFilter.length > 0) {

        newFilter.push('and');
      }
      newFilter.push([this.field['referenceCascade'], '=', 'm.' + this.field['referenceCascade']]);
    }

    classRerefence = this.to['classReference']; // this.model.Reference[this.field.key].IdClass;
    filterReference = this.calculateExpression(newFilter, this.model);
    selectFilterReference = this.calculateExpression(this.to['selectFilterReference'], this.model);
    treeRoot = this.calculateExpression([this.to['treeRoot']], this.model);
    otherClass = domainName = '';

    if(type == "asset") {
      this.dialogService.open(ModalListClassSimpleComponent, {
        closeOnEsc: false,
        context: {
          nameClass: classRerefence,
          domainName: domainName,
          otherClass: otherClass,
          baseFilter: filterReference,
          selectFilter: selectFilterReference,
          treeRoot: treeRoot[0],
          item: this.formState.selectOptionsData[this.field.key]
        },
        hasBackdrop: true,
      }).onClose.subscribe((res) => {
        if (res) {
          setTimeout(() => {
            this.form.get(this.field.key).setValue(null);
          }, 0);
          setTimeout(() => {
            this.form.get(this.field.key).setValue(res[0].Id);
            this.oldValue = res[0].Id;
            this.selectDescription = res ? res[0].Description : '';
          }, 100);
          this.changeSelect(res[0],type);
        }
      });
    } else if(type == "place") {
      this.dialogService.open(ModalListClassComponent, {
        closeOnEsc: false,
        context: {
          nameClass: "Ubicazione",
          domainName: "",
          otherClass: "",
          baseFilter: null,
          selectFilter: null,
          treeRoot: treeRoot[0],
        },
        hasBackdrop: true,
      }).onClose.subscribe((res) => {
        if (res) {
          setTimeout(() => {
            this.form.get(this.field.key).setValue(null);
          }, 0);
          setTimeout(() => {
            this.form.get(this.field.key).setValue(res[0].Id);
            this.oldValue = res[0].Id;
            this.selectDescription = res ? res[0].Description : '';
          }, 100);
          this.changeSelect(res[0],type);
        }
      });
    }

    
  }
  clearDialog(e) {
    setTimeout(() => {
      this.form.get(this.field.key).setValue(null);
      this.model[this.field.key] =null;
      this.oldValue = null;
      // this.selectDescription = '';
    }, 1);

  }
  changeInput() {
    const obj = {Id: this.formControl.value};
    this.changeSelect(obj);
  }

  changeSelect(e,type = "asset") {
    if (!e) return;
    console.log('START change ' + this.field.key + '....');
    
    if (!this.to['reference'] && !this.to['lookup']) return;

    const fieldKey = this.field.key;

    if (this.to['reference']) {
      if (!this.model.Reference) {
        this.model.Reference = [];
      }
      this.model.Reference[fieldKey] = e;
      // Ricalcolo computed
      const referenceValue = e && e['Id'] ? e['Id'] : 0;
      const referenceDomain = this.model.Reference && this.model.Reference[fieldKey] ? this.model.Reference[fieldKey]['IdClass'] : '';
      if (referenceValue && referenceDomain ) {
        this.classService.getComputed(this.to.originalClass, referenceValue, referenceDomain, this.to.reference).subscribe((res) => {
          for (const key in res.data) {
            const index = key;
            const item = res.data[key];
            setTimeout(() => {
              if(this.form.get(index))  this.form.get(index).setValue(item);


            }, 1);

          }
        });
      }

    } else if (this.to['lookup']) {
      if (!this.model.Lookup) {
        this.model.Lookup = [];
      }
      this.model.Lookup[fieldKey] = e;
    }
    setTimeout(() => {
      this.model[fieldKey] = e ? e.Id : null;
      this.model[fieldKey+"_mode"] = type
    }, 1);





  }
  ngOnDestroy() {
    this.onDestroy$.complete();
  }
}
