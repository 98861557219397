import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import moment from 'moment';

@Component({
  selector: "ngx-range-picker",
  templateUrl: "./range-picker.component.html",
  styleUrls: ["./range-picker.component.scss"],
})
export class RangePickerComponent implements OnInit {

  @Output() onChange = new EventEmitter<any>();
  
  today: any;
  dataStart: any;
  dataEnd: any;
  dataStartDisplay: any;
  dataEndDisplay: any;

  private _setCurrentYear: boolean;
  public get setCurrentYear(): boolean {
    return this._setCurrentYear;
  }
  @Input() public set setCurrentYear(value: boolean) {
    this._setCurrentYear = value;
  }

  // ANNI **********************************
  private _annoStart: number;
  dataFinal: string='';
  public get annoStart(): number {
    if (!this._annoStart) {
      this.annoStart = this.today.year();
    }
    return this._annoStart;
  }
  @Input() public set annoStart(v: number) {
    this._annoStart = v;
    this.meseStart_list = this.mese_list;
    this.meseStart = null;

    this.annoEnd_list = this.annoStart_list.filter((z) => z >= v);
    if (!this.annoEnd_list.includes(this.annoEnd)) {
      this.annoEnd = null;
    }
    this.calculateData();
  }

  private _annoEnd: any;
  public get annoEnd(): any {
    return this._annoEnd;
  }
  @Input() public set annoEnd(v: any) {
    this._annoEnd = v;
    this.meseEnd = null;
    this.meseEnd_list = this.mese_list;
   
    this.calculateData();
  }

  annoStart_list: any[] = [];
  annoEnd_list: any[] = [];

  // MESI *************************************
  private _meseStart: any;
  public get meseStart(): any {
    return this._meseStart;
  }
  @Input() public set meseStart(v: any) {
    this._meseStart = v;

    // Sistema giorni
    if (v) {
      const dt = moment(
        this.annoStart + "-" + (v ? v.idx : "01") + "-01",
        "YYYY-MM-DD"
      );
      this.giornoStart_list = this.range(1, dt.endOf("month").date());
    } else {
      this.giornoStart_list = [];
    }
    this.giornoStart = null;
    this.calculateData();
  }
  private _meseEnd: any;
  public get meseEnd(): any {
    return this._meseEnd;
  }
  @Input() public set meseEnd(v: any) {
    this._meseEnd = v;
    if (v) {
      const dtStr = this.annoEnd + "-" + (v ? v.idx : "12") + "-01";
      const endOfMonth = moment(dtStr, "YYYY-MM-DD").endOf("month").date();
      this.giornoEnd_list = this.range(1, endOfMonth);
    } else {
      this.giornoEnd_list = [];
    }

    this.giornoEnd = null;
    this.calculateData();
  }
  meseStart_list: any[] = [];
  meseEnd_list: any[] = [];
  mese_list: any[] = [
    { idx: 1, label: "gennaio" },
    { idx: 2, label: "febbraio" },
    { idx: 3, label: "marzo" },
    { idx: 4, label: "aprile" },
    { idx: 5, label: "maggio" },
    { idx: 6, label: "giugno" },
    { idx: 7, label: "luglio" },
    { idx: 8, label: "agosto" },
    { idx: 9, label: "settembre" },
    { idx: 10, label: "ottobre" },
    { idx: 11, label: "novembre" },
    { idx: 12, label: "dicembre" },
  ];

  // GIORNI
  giornoStart_list: any[] = [];
  giornoEnd_list: any[] = [];
  private _giornoStart: any;
  public get giornoStart(): any {
    return this._giornoStart;
  }
  public set giornoStart(value: any) {
    this._giornoStart = value;
    this.calculateData();
  }
  private _giornoEnd: any;
  public get giornoEnd(): any {
    return this._giornoEnd;
  }
  public set giornoEnd(value: any) {
    this._giornoEnd = value;
    this.calculateData();
  }
  
  clear(){
    this.dataStart=null;
    this.dataEnd=null;
    this.dataFinal='';
    if (this.setCurrentYear) {
      this.today = moment();
      this.annoStart=this.today.year();
      this.conferma();
    } else {
      this.onChange.emit({
        dataStart:null,
        dataEnd:null,
        dataFinal:''
      })
    }
    
  }
  
  calculateData() {
    let anno = this.annoStart;
    let mese = this.meseStart ? this.meseStart.idx : 1;
    let giorno = this.giornoStart ? this.giornoStart : 1;

    this.dataStartDisplay = moment(
      anno + "/" + mese + "/" + giorno,
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");

    anno = this.annoEnd ? this.annoEnd : this.annoStart;
    mese = this.meseEnd ? this.meseEnd.idx : 12;
    const endOfMonth = moment(anno + "/" + mese + "/01", "YYYY-MM-DD")
      .endOf("month")
      .date();

    giorno = this.giornoEnd ? this.giornoEnd : endOfMonth;

    this.dataEndDisplay = moment(
      anno + "/" + mese + "/" + giorno,
      "YYYY-MM-DD"
    ).format("DD/MM/YYYY");

    const isAfter=moment(this.dataStartDisplay,"DD/MM/YYYY").isAfter(moment(this.dataEndDisplay,"DD/MM/YYYY"));
    if (isAfter) {
      setTimeout(() => {
        this.annoEnd=null;
        this.meseEnd=null;
        this.giornoEnd=null;
      }, 100);
      
    }

  }
  clearNgSelect(e) {
    window.event.stopPropagation()
    
  }
  popoverShow(){
   
      
      this.today = moment();
      this.annoStart_list = this.range(this.today.year() - 10, this.today.year());
      this.annoStart=this.today.year();
      this.annoEnd=null;
 
    
  }
  constructor() {}

  ngOnInit() { 
    if (this.setCurrentYear) {
      this.today = moment();
      this.annoStart=this.today.year();
      this.conferma();
    }
    
  }
  range(start, end) {
    return Array(end - start + 1)
      .fill(0)
      .map((_, idx) => start + idx);
  }
  conferma(){
    this.dataStart=this.dataStartDisplay;
    this.dataEnd=this.dataEndDisplay;
    this.dataFinal=this.dataStart+' ' + (this.dataEnd?' - '+this.dataEnd:'');
    console.log(this.dataFinal);

    this.onChange.emit({
      dataStart:moment(this.dataStart,"DD/MM/YYYY").format("YYYY-MM-DD"),
      dataEnd:moment(this.dataEnd,"DD/MM/YYYY").format("YYYY-MM-DD"),
      dataFinal:this.dataFinal
    })
  }
}
